<template>
  <div>
    <v-card>
      <v-card-title class="indigo darken-4 white--text">
        <v-icon dark class="mx-4" @click="$router.go(-1)">arrow_back</v-icon>
        Registro de Acitivad
      </v-card-title>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5,10,15]
        }"
        :headers="headers"
        :items="allUsersActivity"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px"> </v-dialog>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialogNewUser: false,
    dialog: false,

    headers: [
      {
        text: "Usuario",
        align: "left",
        filterable: true,
        value: "user.username",
        width: "20%",
      },
      { text: "Nombre(s)", value: "user.firstname", width: "20%" },
      { text: "Apellidos(s)", value: "user.lastname", width: "20%" },
      { text: "Dirección IP", value: "ip_address", width: "20%" },
      { text: "Fecha de Conexión", value: "conexion", width: "25%" },
      { text: "Fecha de Desconexión", value: "desconexion", width: "25%" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    ...mapGetters([
      // map this.productItems to this.$store.getters.productItems
      "allUsersActivity",
    ]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.$store.dispatch("getUsersActivity");

    //this.initialize();
  },
};
</script>

<style lang="scss" scoped></style>
